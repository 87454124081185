<template>
  <div>
    <div class="viewOnlineExam container" style="backgroun: #7297ff">
      <v-row>
        <v-col cols="12">
          <!-- <div class="title">
            <h1>{{ ExamData.class_name }}</h1>
          </div> -->
          <div class="text-center">
            <div class="box-cobtainer">
              <div class="value">
                <span class="main-color">Dear Parents,</span>
                <br />
                Based on the school’s differentiation strategy, you are kindly
                requested to consider the entrance exam as a preliminary step
                before your child’s journey in our school starts.<br />
                Accordingly, your child will sit for another (unpaid)
                standardized test at the beginning of the academic year. The
                result of the test will determine the student’s level and be our
                guidance for any educational plans that aim to nurture our
                students’ academic progress. Furthermore,<br />
                the outcome of this test might lead to a conditional acceptance
                of your child.<br />
                Here are some key points about the entrance exam:<br />
                <ul>
                  <li>
                    It targets the three major subjects: Math, English, and
                    Arabic.
                  </li>

                  <li>It lasts for a duration of three hours.</li>

                  <li>
                    It is one-time exam. It cannot be repeated or accessed after
                    submission.
                  </li>

                  <li>The exam results will be sent to you via a message.</li>
                </ul>

                <span class="main-color">Thank you for your cooperation</span>
              </div>
              <hr />
              <div dir="rtl" class="ar-p">
                <span class="main-color">أولياء الأمور الكرام,</span>
                <br />
                نود لفت عنايتكم إلى اعتبار اختبار القبول هذا خطوة
                مبدئيَّةًللرِّحلة التعليمية لابنكم/ ابنتكم في المدرسة. <br />
                وعليه سيتم إخضاع الطالب/ــة لاختبار تحديد مستوى (غير مدفوع) في
                بداية العام الدراسي، <br />
                الذي يترتب عليه قبول مشروط للطالب/ـة في المدرسة، ومن خلال
                النتائج سيتم وضع خطة وتحديد أهداف للرَّفع من مستوى الطالب
                أكاديميًا وتعزيزه فرديًّا.
                <br />
                الرّجاء أخذالنقاط التالية بعين الاعتبار قبل أداء الاختبار:
                <br />
                <ul>
                  <li>
                    يهدف اختبار القبول لقياس مهارات الطالب/ة في المواد الأساسية
                    التالية؛ اللغة الإنجليزية، واللغة العربية، و الرياضيات.
                  </li>

                  <li>مدة الاختبار ثلاث ساعات.</li>

                  <li>لا يسمح بإعادة الاختبار بعد التسليم تحت أي ظرف.</li>

                  <li>يتم إرسال نتيجةالاختبار عن طريق البريد الإلكتروني .</li>
                </ul>

                <span class="main-color text-center">نشكر تعاونكم</span>
              </div>
              <hr class="main-color" />
              <v-checkbox
                color="blue lighten-3"
                outlined
                v-model="data.readFinancial"
              >
                <template slot="label">
                  <a
                    @click.stop
                    href="https://jwa.edu.sa/financial-policy-of-jeddah-world-academy/"
                    target="blank"
                    class="main-color"
                  >
                    {{ $t("Check You Have Read Financial Policy") }}</a
                  >
                </template>
              </v-checkbox>
              <v-checkbox
                color="blue lighten-3"
                outlined
                v-model="data.admissionRequirement"
              >
                <template slot="label">
                  <a
                    @click.stop
                    href="https://jwa.edu.sa/admission-requirements/"
                    class="main-color"
                    target="blank"
                  >
                    {{ $t("Check You Have Read Admission Requirements") }}</a
                  >
                </template>
              </v-checkbox>
              <hr class="main-color" />
              <div class="actions">
                <v-btn class="modal-btn-cancel mr-2" large @click="notAgree">{{
                  $t("Not Agree")
                }}</v-btn>
                <v-btn
                  v-if="data.readFinancial && data.admissionRequirement"
                  class="modal-btn-save"
                  large
                  @click="agree"
                  :loading="loading"
                  :disabled="loading"
                  >{{ $t("yes I agree") }}</v-btn
                >
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
// import axios from "axios";
export default {
  name: "applicantViewOnlineExam",
  data() {
    return {
      examId: "",
      loading: false,
      showExamErrorText: "",
      data: {
        readFinancial: false,
        admissionRequirement: false
      }
    };
  },
  methods: {
    agree() {
      this.$router.push(
        {
          path:
            "/ConfirmAdmissionExam/" +
            this.examId +
            "/" +
            this.$router.currentRoute.params.applicant_code
        },
        () => {}
      );
    },
    notAgree() {
      this.$router.push(
        {
          path: "/finishExam"
        },
        () => {}
      );
    }
  },
  mounted() {
    this.examId = this.$router.currentRoute.params.examId;
  }
};
</script>
<style lang="scss" scoped>
@import "../../../styles/_variables.scss";
@import "../../../styles/_forms.scss";
.box-cobtainer {
  max-width: 750px;
  background: #f7f7f7;
  margin: auto;
  margin-top: 8%;
  padding: 50px;
  color: #242323;
}
ul {
  list-style: disc !important;
}
.value {
  font-size: 20px;
  text-align: left;
  line-height: 1.7;
}
.ar-p {
  text-align: right;
  font-size: 20px;
  line-height: 1.7;
}
.title h2 {
  margin-bottom: 0 !important;
  font-size: 1.5rem;
}
.info-list {
  padding-top: 0;
  padding-bottom: 0;
  li {
    display: inline-block;
    width: 20%;
    font-size: 13px;
    padding-right: 2px;
  }
}

@media (max-width: 600px) {
  .info-list {
    li {
      font-size: 10px;
    }
  }
}

.icons-div,
.hr-div {
  padding-top: 0;
  padding-bottom: 0;

  hr {
    margin-bottom: 0 !important;
    margin-top: 0.5rem !important;
  }
}

.exam-section {
  margin-bottom: 1rem;
}

.sectionInput {
  background-color: #eff3ff;
  padding: 25px 0px 25px 50px;
}
hr.hr-bold {
  border-top: 2px solid rgb(18 2 2 / 10%);
}

label {
  margin-top: 0 !important;
}
.clickable {
  cursor: pointer;
}
.questionbody {
  box-shadow: $box-shadow;
  padding-bottom: 2px !important;
  // margin-top: 2px;
  margin-bottom: 2px;
}
.addQuestion {
  padding: 10px;
  background-color: #eff3ff;
}

.addOption {
  padding: 5px;
  background-color: #eff3ff;
}
.divider {
  height: 2rem;
  width: 100%;
  background-color: #eff3ff;
  margin: 0 !important;
  padding: 0 !important;
}

.displayDiv {
  display: block !important;
}
.hiddenDiv {
  display: none;
}
.loader img {
  margin-top: 50px;
  display: inline-block;
  margin-right: 2rem;
}
</style>
